<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item @click.native="goto()">售后记录</el-breadcrumb-item>
              <el-breadcrumb-item>查看详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rightes">
                <div class="box_right_top">查看详情</div>
                <div class="box_right_cent" v-for="(item,index) in options" :key="index">
                    <div class="return-box">
                        <div class="flex-start return">
                            <div class="return-text">申请单单号</div>
                            <div class="return-cent">{{item.afterSaleNo}}</div>
                        </div>
                        <div class="flex-start return">
                            <div class="return-text">原订单号</div>
                            <div class="return-cent">{{item.orderNo}}</div>
                        </div>
                        <div class="flex-start return">
                            <div class="return-text">售后类型</div>
                            <div class="return-cent">{{typeName(item.type)}}</div>
                        </div>
                        <div class="flex-start return">
                            <div class="return-text">售后数量</div>
                            <div class="return-cent">{{item.goodsTotalNum	}}</div>
                        </div>
                        <div class="flex-start return">
                            <div class="return-text">售后金额</div>
                            <div class="return-cent">{{item.goodsTotalMoney}}</div>
                        </div>
                        <div class="flex-start return">
                            <div class="return-text">申请原因</div>
                            <div class="return-cent">{{item.reason}}</div>
                        </div>
                        <div class="flex-start returns">
                            <div class="return-text">申请售后说明</div>
                            <div class="return-cent">
                              <div class="flex-center" v-if="picImg.length != 0">
                                <div v-for="(item1,index1) in picImg" :key="index1">
                                    <img :src="item1" alt="" style="width:80px;margin-right:8px;">
                                </div>
                              </div>
                               
                              <div>{{item.description}}</div>
                            </div>
                        </div>
                        <div class="flex-start return" v-if="item.type == 2 || item.type == 5">
                            <div class="return-text">申请售后地址</div>
                            <div class="return-cent">{{item.returnAddress}}</div>
                        </div>
                        <div class="flex-start return">
                            <div class="return-text">申请时间</div>
                            <div class="return-cent">{{item.createTime}}</div>
                        </div>
                        <div class="flex-start return">
                            <div class="return-text">申请结果</div>
                            <div class="return-cent">{{stateName(item.state,item.refuseReason)}}</div>
                        </div>
                        <div class="flex-start return" v-show="item.state==2">
                            <div class="return-text">退货地址</div>
                            <div class="return-cent">{{item.returnAddress}}  {{item.returnUser}} {{item.returnPhone}}</div>
                        </div>
                        <!-- <div class="flex-start return" v-show="logisticsUser.expNo">
                            <div class="return-text">售后物流信息</div>
                            <div class="return-cent">{{'快递公司：'+logisticsUser.expCompany+'，快递单号：'+logisticsUser.expNo}}</div>
                        </div> -->
                        <div class="flex-start return" v-if="logisticsAry.expNo">
                            <div class="return-text">(供应商寄回)物流信息</div>
                            <div class="return-cent">{{'快递公司：'+logisticsAry.expCompany+'，快递单号：'+logisticsAry.expNo}}</div>
                        </div>
                        <div>
                            <el-table
                            :data="item.goodsInfos"
                            border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                            :cell-style="{ textAlign: 'center' }"
                            style="width: 100%;text-align: center;">
                                <el-table-column
                                prop="goodsId"
                                label="退款商品">
                                </el-table-column>
                                <el-table-column
                                prop="goodsPic"
                                label="退货商品图片">
                                <template slot-scope="scope">
                                    <img style="width:100px;" :src="scope.row.goodsPic" alt="">
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="goodsName"
                                label="退货商品名称">
                                </el-table-column>
                                <el-table-column
                                prop="refundMoney"
                                label="售后商品总价">
                                <template slot-scope="scope">
                                  <span>{{scope.row.refundMoney * scope.row.refundNum}}</span>
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="refundNum"
                                label="数量">
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="return-btnbox ">
                      <div v-if="identityFun(['采购员'])" class="flex-center">
                        <div v-show="item.state==1">
                          <div class="return-btn" @click="withdraw(item.id)">取消申请</div>
                          <div class="return-btn" @click="amend(item)">修改申请</div>
                        </div>
                        <div v-show="item.state==2">
                          <div class="return-btn" @click="withdraw(item.id)">取消申请</div>
                          <div class="return-btn" @click="fillLogistics(item.id)" v-show="fill==1">填写物流信息</div>
                        </div>
                        <!-- <div v-show="item.state==5">
                          <div class="return-btn" @click="goback()">返回</div>
                        </div> -->
                        <!-- <div v-show="item.state==3 || item.state==4 || item.state==8 || item.state==9">
                          <div class="return-btn" @click="goback()">返回</div>
                        </div> -->
                        <div v-show="item.state==6 || item.state==7">
                          <div class="return-btn" @click="applyAgain(item)">重新申请</div>
                        </div>
                        <div v-show="item.state==9">
                          <div class="return-btn" @click="salesConfirm(item)">售后确认</div>
                        </div>
                        <div class="return-btn" @click="goback()">返回</div>
                      </div>
                      <div v-else-if="identityFun(['项目审批员','管理员','总部审批员', '外部企业管理员'])" class="flex-center">
                        <div class="return-btn" @click="gobacks()">返回</div>
                      </div>
                    </div>
                    <el-dialog
                      title="填写物流信息"
                      :visible.sync="dialogVisible"
                      width="30%">
                      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="快递公司" prop="name">
                          <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="快递单号" prop="number">
                          <el-input v-model="ruleForm.number"></el-input>
                        </el-form-item>
                      </el-form>
                      <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="countersign()">确 定</el-button>
                      </span>
                    </el-dialog>
                </div>
                
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { 
  viewdetails,
  getStoreManagerSendInfo,
  logisticsinformat,
  cancelsales,
  changeGoodsComplete 
} from "@/api/order/aftersale"
// import { orderafter } from "@/api/order/order"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        fill:1,
        currentPage:1,
        options: [],
        value: '',
        ruleForm: {
          name: '',
          number:''
        },
        rules: {
          name: [
            { required: true, message: '请输入快递公司', trigger: 'blur' },
          ],
          number: [
            { required: true, message: '请输入快递单号', trigger: 'blur' },
          ],
        },
        dialogVisible:false,
        afterSaleId:'',
        identity:'',
        picImg:[],
        logisticsAry: {
          expCompany: '',
          expNo: ''
        },
        logisticsUser: {
          expCompany: '',
          expNo: ''
        },
      }
  },
  computed: {
    typeName() {
        return (type) => {
            switch (type) {
                case 1:
                    return "退款";
                case 2:
                    return "退货退款";
                case 5:
                    return "换货";
                case 6:
                    return "维修";
            }
        };
    },
    stateName(){
      return (state,refuseReason) => {
        switch (state) {
          case 1:
            return "未处理";
          case 2:
            return "已同意";
          case 3:
            return "售后处理中";
          case 4:
            return "售后处理中";
          case 5:
            return "已完成";
          case 6:
            return "已拒绝("+refuseReason+")";
          case 7:
            return "已取消";
          case 8:
            return "售后处理中";
          case 9:
            return "售后处理中";
        }
      };
    }
  },
  created(){
    // console.log(this.$route.query.id)
    let id =this.$route.query.id
    viewdetails(id).then((data)=>{
      if(data && data.code=='00000'){
        // console.log(data.data);
        this.options.push(data.data)
        if(!data.data.pic){
          this.picImg=[]
        }else{
          let arr = data.data.pic.split(",");
          this.picImg=arr
        }
      }
    })
    // orderafter({
    //   "orderId":id,
    // }).then((res)=>{
    //   console.log(res);
    //   if(res.code == '00000'){
    //     this.logisticsAry = res.data.sendOutGoodsVOS[res.data.sendOutGoodsVOS.length - 1].mapList[0]
    //   }
    // })
    // 物流信息
    getStoreManagerSendInfo(id).then((res)=>{
      console.log(res);
      if(res.code == '00000'){
        // this.logisticsUser = res.data
        this.logisticsAry = res.data
      }
    })
    this.identity = JSON.parse(localStorage.getItem('identity'));
  },
  methods:{
    identityFun(ary){
      let flag = false
      for (let i = 0; i < this.identity.length; i++) {
        if(ary.indexOf(this.identity[i].roleName) !== -1){
          flag = true
          break
        }
      }
      return flag
    },
    //返回
    goback(){
      this.$router.push({ name: 'SalesRecord' })
    },
    gobacks(){
      this.$router.push({ name: 'ProcurementAccount' })
    },
    //取消售后
    withdraw(id){
      this.$confirm('确认取消售后', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log(id)
        cancelsales(id).then((data)=>{
          // console.log(data)
          if(data.code=='00000'){
            this.$message({
              type: 'success',
              message: '取消成功!'
            });
            this.$router.push({ name: 'SalesRecord' })
          }else{
            this.$message.error(data.desc);
          }

        })
      }).catch(() => {});
    },
    // 售后确认按钮
    salesConfirm(item){
      // console.log(item);
      this.$confirm('确认操作', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeGoodsComplete({afterSaleId:item.id}).then(res=>{
          console.log(res);
          if(res.code == '00000'){
            this.$message({
              type: 'success',
              message: '执行成功！'
            });
            this.$router.push({ name: 'SalesRecord' })
          } else {
            this.$message({
              type: 'error',
              message: '执行失败！'
            });
          }
        })
      })
      
    },
    //填写物流信息
    fillLogistics(id){
      this.dialogVisible=true
      this.afterSaleId=id
    },
    countersign(){
        if(this.ruleForm.name!=''&&this.ruleForm.number!=''){
          let data={
            "afterSaleId": this.afterSaleId,
            "sendCompanyAndNo": this.ruleForm.name +"_"+this.ruleForm.number
          }
          logisticsinformat(data).then((data)=>{
            if(data.code==0){
              this.$message({
                type: 'success',
                message: '填写成功!'
              });
              this.dialogVisible=false
              this.fill=2
            }
          })
        }else{
          this.$message.error('请填写信息');
        }
        // this.$refs['ruleForm'].validate((valid) => {
        //   if(valid){
        //     let data={
        //       "afterSaleId": this.afterSaleId,
        //       "sendCompanyAndNo": this.ruleForm.name +this.ruleForm.number
        //     }
        //     console.log(data, '')
            // logisticsinformat(data).then((data)=>{
            //   if(data.code==0){
            //     this.$message({
            //       type: 'success',
            //       message: '填写成功!'
            //     });
            //     this.dialogVisible=false
            //     this.afterSale('','','',this.pageNum,this.pageSize)
            //   }
            // })
        //   }
        // })
      },
    //重新申请
    applyAgain(item){
      this.$router.push({ 
        name: "ApplySales",  
        query: { 
          name:JSON.stringify({orderNo:item.orderNo,createTime:item.createTime}),
          list:JSON.stringify(item)},
        }
      );
    },
    goto(){
      this.$router.push({ name: 'SalesRecord'})
    },
    amend(item){
      this.$router.push({ name: "ApplySales",  query: { name:JSON.stringify(item)}});
    }

  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rightes{
    width: 87%;
    // height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .return-box{
        margin: 10px 20px;
        border: 1px solid #e7e7e7;
        border-bottom:none;
        .return{
            height: 30px;
            line-height: 30px;
            border-bottom: 1px solid #e7e7e7;
            .return-text{
                width: 25%;
                text-align: center;
                border-right: 1px solid #e7e7e7;;
            }
            .return-cent{
                width: 75%;
                text-align: center;
            }
        }
        .returns{
            border-bottom: 1px solid #e7e7e7;
            .return-text{
                width: 25%;
                min-height: 35px;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .return-cent{
                width: 75%;
                min-height: 30px;
                text-align: center;
                border-left: 1px solid #e7e7e7;
            }
        }
        
        
    }
    .return-btnbox{
            margin: 25px 0;
            .return-btn{
                width: 100px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                border: 1px solid #999;
                background: #f2f2f2;
                margin-right: 18px;
                border-radius: 5px;
                display: inline-block;
                cursor: pointer;

                &:hover{
                  background: #f1eded;
                }
            }
        }
   
  }
}
</style>