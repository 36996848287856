import request from '@/utils/request'
// import qs from 'qs'


/**
 * 售后记录查询
 * @param data
 */
 export function afterSale(data) {
    return request({
        url: 'aftersale/after-sale-info/queryAfterSaleInfoList',
        method: 'post',
        data
    })
}

/**
 * 退换货查询
 * @param data
 */
 export function returnOrder(data) {
    return request({
        url: 'aftersale/after-sale-info/ableAfterSaleInfoList',
        method: 'post',
        data
    })
}

/**
 * 查看某个商品规格
 * @param id
 */
 export function returnspecificat(id) {
    return request({
        url: 'aftersale/after-sale-info/querySkuAndValuesByGoodsId/'+id,
        method: 'get'
    })
}

/**
 * 申请售后
 * @param data
 */
 export function applyforsales(data) {
    return request({
        url: 'aftersale/after-sale-info/afterSaleApply',
        method: 'post',
        data
    })
}

/**
 * 查看详情
 * @param id
 */
 export function viewdetails(id) {
    return request({
        url: 'aftersale/after-sale-info/afterSaleInfoDetail/'+id,
        method: 'get'
    })
}

/**
 * 查看售后物流详情
 * @param id
 */
 export function getStoreManagerSendInfo(id) {
    return request({
        url: 'orders/orders/send-out-goods/getStoreManagerSendInfo/'+id,
        method: 'get',
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded'
        // },
        // data: {
        //     afterSaleId: id
        // }
    })
}

/**
 * 取消售后
 * @param id
 */
 export function cancelsales(id) {
    return request({
        url: 'aftersale/after-sale-info/cancelApply/'+id,
        method: 'get'
    })
}

/**
 * 填写物流信息
 * @param data
 */
 export function  logisticsinformat(data) {
    return request({
        url: 'aftersale/after-sale-info/afterSaleRefundLogisticsApply',
        method: 'post',
        data
    })
}

/**
 * 申请纸质发票订单商品展示
 * @param data
 */
 export function  applygoods(data) {
    return request({
        url: 'orders/order/invoice/getInvoiceGoodsList',
        method: 'post',
        data
    })
}


/**
 * 申请纸质发票
 * @param data
 */
 export function  applypaper(data) {
    return request({
        url: 'orders/order/invoice/addInvoice',
        method: 'post',
        data
    })
}


/**
 * 查看发票内容
 * @param data
 */
 export function  applydetails(data) {
    return request({
        url: 'orders/order/invoice/getInvoice',
        method: 'post',
        data
    })
}


/**
 * 获取采购员账号列表
 * @param 
 */
 export function purchase() {
    return request({
        url: 'aftersale/after-sale-info/getStaffInfoList',
        method: 'get',
    })
}


/**
 * 获取京东商品售后属性
 * @param 
 */
 export function afterAttribute(id) {
    return request({
        url: 'aftersale/after-sale-info/getJDGoodsAfterSaleAttribute/'+id,
        method: 'get',
    })
}



/**
 * 修改售后
 * @param 
 */
 export function updateAfterSaleApply(data) {
    return request({
        url: 'aftersale/after-sale-info/updateAfterSaleApply',
        method: 'post',
        data
    })
}

/**
 * 取消订单接口(已付款未发货)
 * @param 
 */
 export function cancelPaidOrder(id) {
    return request({
        url: 'aftersale/after-sale-info/cancelPaidOrder/'+id,
        method: 'get',
    })
}

/**
 * 用户换货手动完结
 * @param 
 */
 export function changeGoodsComplete(data) {
    return request({
        url: `aftersale/after-sale-info/changeGoodsComplete/${data.afterSaleId}`,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}